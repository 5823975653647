<template>
  <div class="content pad-bottom--xl">
    <div class="grid-container pad-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad-top">
          <h1 class="text-white large-heading pad-bottom">When things go wrong: A Clinician's duty towards the patient and those close to them</h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad bg--white">
          <div
            class="relative">
            <img src="../assets/slides/Slide2.jpeg" />
            <router-link to="/module-one-content">
              <button
                style="position: absolute;
                bottom: 45px;
                right: 60px;"
                @click="showIntro = true"
                class="button">View module</button>
              </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleOne',
  data() {
    return {
      showIntro: false,
    };
  },
};
</script>
